/* --- Universal styles --- */ 

:root {
  font-size: 16px;
}


html {
  height: 100%;
}


.Default-Styling {
  background: #eeecec;
  margin: 8px;

  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: rgb(110, 110, 110);

  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  --default-height: 97vh;
  --default-width: 90vw;


  --secondary_color:#FFBF49;
  --tertiary_color:#FD4855;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  

}



.Overall-Parent{
  align-content: start;
  margin-top: 4.5rem;
  margin-right: 0.5rem;
}




/* ----- Button Universal ----- */ 

/*
Would perhaps use unset itself, but it causes issues with IOS safari in some cases.
*/

.Button-Unset{
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: inherit;
  display: block;
  text-align: left;

  cursor: pointer;
  height: 100%;
  width: 100%;
}


.Button-Unset:hover{
  color: purple;
}

.Button-Unset:visited{
  color: purple;
}



/* ----- Navbar ----- */ 


.Nav-Flex-Parent{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  height: 3rem;
  width: 100vw;
  color: rgb(146, 146, 146);
  font-size:1.25rem;
  background: #1b1b1b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  text-decoration: underline;
}

.Nav-Selected{
  color: sandybrown;  
}

.Nav-Link{
  color: inherit;

}
a:hover{
  color: purple;
}


/*
Just to normalize appearance amongst navbar elements - for example, without all:inherit, the github link will
color its underline on hover as well, unlike the other navbar elements.
*/


.Nav-Link:hover{
  all: inherit;
  cursor: pointer;
  color: purple;
}





/* ----- News ----- */ 


.News-Grid-Parent{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  font-size:1.5rem;
  gap: 4rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}


.News-Square-Button{
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  border-radius: 2px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  padding-left: 0.8rem;
  padding-bottom: 0.8rem;
}

/*
The box shadow etc is being placed on the button itself rather than the div inside the button.

Making the whole news blocks clickable as buttons runs into some idiosyncrasies that have to
be worked around. Buttons behave strangely, unfortunately.
The box shadow has to be attached to the button in order to show up on ios safari,
rather than working while attached to a div inside the button.

Since the box shadow, border and padding are all spatially related, I kept them together.

But because buttons are weird about other things, such as grid, I kept other properties 
inside News-Square instead.
*/

.News-Square-Div{
  display: grid;
  align-content: space-around;
  align-items: end;
  color: rgb(14, 14, 196);
  height: 100%;
  width: 100%;
}


.News-Square-Div:hover{
  color:purple;
}


.News-Title-Text{
  text-decoration: underline;
}


/*
No size restriction such as hiding overflow etc, currently relying
on character limits for the content to prevent overflow issues,
such as limits that would be enforced by a database.
*/

.News-Preview-Text{
  color: #121212;
  padding-top: .75rem;
  font-size: 1rem;
  line-height: 1.25rem;
  
}





/* ----- Article ----- */ 

.Article-Parent{
}

.Article-HTML-Segment{
  display: grid;
  font-size: 1.25rem;
  justify-content: center;
  margin: 0rem 0.75rem 0rem 0.75rem;
  line-height: 1.75rem;
  color: #1d1d1d;
}

.Article-HTML-Segment > div {
  max-width: 50rem;
  margin-bottom: 0.75rem;
}

.Article-HTML-Segment > div > div {
  margin-bottom: 0.5rem;
}




/* ----- Graph ----- */ 

.antipointer{
  pointer-events: none
}


.Graph-Y-Axis-Foreign{
  font-size: 0.75rem;
  text-align: right;    
}

.Graph-X-Axis-Foreign{
  font-size: 0.92rem;
  height: 55.5rem;
  color: black;
  line-height: 0.85rem;
  text-align: center;
}


.Graph-Title{
  font-size: 1.5rem;
  text-align: center;
  color: #1d1d1d;
  margin-top: 1rem;
}

/*
Centering in this way because recharts has some bad interactions with flex/grid containers.
*/

.Graph-Overall{
  max-width: 80rem;
  margin: 0 auto;
}



.Revert-Graph-Parent{
  margin: 1rem 0rem 1rem 0rem;
  text-align: center;
}

.Revert-Graph{
  font-family: inherit;
  font-size: inherit;
  height: 2.6rem;
  line-height: 2.6rem;
  padding: 0 .8rem 0 1.1rem;
  color: #eeecec;
  background: rgb(15, 52, 175);
  border-right: 0rem;
  border-bottom: 0.24rem solid gray;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.2);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.2);


}


.Revert-Graph:hover {
  background-color:rgb(13, 44, 145);
}


.Revert-Graph:focus {
  outline: 2px solid #121212;
}
.Revert-Graph:active {
  background-color:rgb(13, 44, 145);
  transform: translateY(0.1rem);
}



/*
The styles below are modifications for recharts's built in styles.
*/

.recharts-cartesian-axis-ticks{
  font-size: .7rem;
  font-family: inherit;
}

.recharts-xAxis{
  height: 55.5rem;
}


.recharts-cartesian-axis-tick{
  height: 55.5rem;
}




/* ----- Screenreader Content----- */ 

.Screen-Reader-Button{
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: #121212;
  text-decoration: inherit;
  display: block;
  text-align: center;

  cursor: pointer;
  width: 100%;
  z-index: 900;
}


.Screen-Reader-Button:focus{
  border: 2px solid #121212;
}

.Screenreader-Only{
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}




/* ----- Responsive Styling----- */ 


@media (min-width:900px) and (min-height: 800px){

  .Article-HTML-Segment{
    font-size: 1.5rem;
  }

  .Article-HTML-Segment > div {
    line-height: 2.25rem;
  }
  
}




@media (max-width:500px){

  .Graph-X-Axis-Foreign{
    font-size: 0.63rem;
  }

  .Article-HTML-Segment{
    display: grid;
    font-size: 1.1rem;
    justify-content: center;
  }

  
.Article-HTML-Segment > div {
  line-height: 1.75rem;
}


.recharts-default-tooltip{
  display: none;
}

.recharts-tooltip-cursor{
  display: none;
}


.Button-Unset:hover{
  color: sandybrown;
}
}



